import React, {useState} from 'react';
import axios from 'axios';
import memoriamOverlay from '../media/evan/memoriam-overlay.png';
import oneOfOneOverlay from '../media/evan/one-of-one.png';
import evanOverlay from '../media/evan/evan.png';


function Evan() {

  const wildBunchAddress = '0xe9A1a323b4c8FD5Ce6842edaa0cd8af943cBdf22'

  let offsetX, offsetY = 0.0625
  let zoomFactor = 1.125

  const [wildOne, setWildOne] = useState([0, [], null])
  const [errorMessage, setErrorMessage] = useState('')

  const overlay = new Image()
  overlay.src = memoriamOverlay
  

  for(let i = 0; i < wildOne[1].length; i++) {
    if(wildOne[1][i].trait_type === 'Facing' && wildOne[1][i].value === 'Right') {
      overlay.src = memoriamOverlay
      offsetX = 0.0625
      offsetY = 0.02
      zoomFactor = 1.125
      overlay.onload = draw
    } else if(wildOne[1][i].trait_type === 'Facing' && wildOne[1][i].value === 'Left') {
      overlay.src = memoriamOverlay
      offsetX = 0.0625
      offsetY = 0.0625
      zoomFactor = 1.125
      overlay.onload = draw
    } else if(wildOne[0] === 3644) {
      overlay.src = evanOverlay
      offsetX = -0.14
      offsetY = -0.16
      zoomFactor = 0.68
      overlay.onload = draw
    } else if(wildOne[1][i].trait_type === 'Status' && wildOne[1][i].value === 'Unknown') {
      overlay.src = oneOfOneOverlay
      offsetX = 0
      offsetY = 0
      zoomFactor = 1
      overlay.onload = draw
    }

  }






  const baseImg = new Image()
  baseImg.crossOrigin = "Anonymous"
  baseImg.src = wildOne[2]
  baseImg.onload = draw

  function draw() {

    setTimeout(() => {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = 320
      //adjust to ratio of screen width
      canvas.height = 320
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      

      //adjust to ratio of screen width
      ctx.drawImage(baseImg, -(canvas.width*offsetX), -(canvas.height*offsetY), (canvas.width*zoomFactor), (canvas.height*zoomFactor))
      //adjust to ratio of screen width
      ctx.drawImage(overlay, 0, 0, canvas.width, canvas.height)
      //adjust to ratio of screen width


      // Load the canvas image into a Uint8ClampedArray
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Replace all red pixels with green pixels
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];

        if (r === 75 && g === 63 && b === 84) {
          data[i + 3] = 0; // Set alpha to 0
        }
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Put the modified image data back onto the canvas
      ctx.putImageData(imageData, 0, 0);



      const canvasFull = document.getElementById('canvas-full')
      const ctxFull = canvasFull.getContext('2d')
      canvasFull.width = 1000
      canvasFull.height = 1000
      ctxFull.fillStyle = 'white'
      ctxFull.fillRect(0, 0, canvasFull.width, canvasFull.height)
      ctxFull.drawImage(baseImg, -(canvasFull.width*offsetX), -(canvasFull.height*offsetY), (canvasFull.width*zoomFactor), (canvasFull.height*zoomFactor))
      ctxFull.drawImage(overlay, 0, 0, canvasFull.width, canvasFull.height)

      // Load the canvas image into a Uint8ClampedArray
      const imageDataFull = ctxFull.getImageData(0, 0, canvasFull.width, canvasFull.height);
      const dataFull = imageDataFull.data;

      // Replace all red pixels with green pixels
      for (let i = 0; i < dataFull.length; i += 4) {
        const r = dataFull[i];
        const g = dataFull[i + 1];
        const b = dataFull[i + 2];

        if (r === 75 && g === 63 && b === 84) {
          dataFull[i + 3] = 0; // Set alpha to 0
        }
      }

      ctxFull.clearRect(0, 0, canvasFull.width, canvasFull.height)

      // Put the modified image data back onto the canvas
      ctxFull.putImageData(imageDataFull, 0, 0);
    }, "500")
  }

  

  const setWildOneData = (event) => {
      const holder = document.getElementById('wildOneMemoriam')
      const wildOne = Number(holder.value)

      event.preventDefault()
      window.location.href = '#canvas'

      if(wildOne > 4000 || wildOne < 1) {
        setErrorMessage('Invalid ID')
      } else {
        setErrorMessage('')
        
        const apiKey = process.env.OS_API_KEY
        const baseURL = 'https://api.opensea.io/api/v1/asset/'+wildBunchAddress+'/'+wildOne+'/';

        var config = {
          method: 'GET',
          url: baseURL,
          headers: { 
            'X-API-KEY': apiKey
          }
        };
        axios(config)
        .then(response => {
          const newMetadata = response.data
          const baseImgUrl = new URL(newMetadata.image_url) 
          baseImgUrl.search = 'w=1000&auto=format'
          setWildOne([wildOne, newMetadata.traits, baseImgUrl])
        })
        .catch(error => console.log(error))
        
      }

    }

  function WildOneInput() {
    
    return (
        <div className="d-flex flex-column w-100 wo-input">
          <form onSubmit={setWildOneData}>
          <span className="text-danger my-2 error-message">{errorMessage}</span>
          <input className="form-control mb-3" placeholder={'Wild One #'+wildOne[0]} type="number" id="wildOneMemoriam" min="1" max="4000" step="1" required />
          <button className="btn btn-danger add-filter-btn mb-3 w-100" onClick={() => setWildOneData()}>MEMORIALIZE</button>
          </form>
        </div>
      )
    
  }

  function saveImage(element) {
    const canvas = document.getElementById('canvas-full')
    const image = canvas.toDataURL("image/jpg")
    const win = window.open()
    win.document.write('<img src="'+image+'" height="1000" width="1000" />')
  }
  

  return (

    <div className="d-flex flex-column align-items-center justify-content-center content-overflow">

      <div className="d-flex flex-roww-100 px-2">
        <h1 className="content-header w-100 text-light">In Memory of Evan</h1>
      </div>

      <div className="d-flex flex-row w-100 px-2">
        <span className="steez mb-3 w-100 text-center text-light">Enter your Wild One ID and <span className="steez-bold">MEMORIALIZE</span> our Wild Bunch Brother, Evan</span>
      </div>
      
      

      <div className="d-flex flex-column flex-lg-row justify-content-center w-100 mb-3 px-3">

        <div className="d-flex flex-column align-items-center order-2 order-lg-1">
          <canvas id="canvas" className="canvas-4 mb-4"></canvas>
          {//adjust to ratio of screen width
          }
        </div>

        
        <div className="d-flex flex-column align-items-center justify-content-center px-lg-4 order-1 order-lg-2">
          <WildOneInput />
          <button className="btn btn-danger add-filter-btn mb-3 w-100" onClick={() => saveImage()}>Download</button>
        </div>

      </div>

      <div className="d-none flex-column align-items-center">
        <canvas id="canvas-full" className="canvas-full-2 mx-auto mb-4" width="1000" height="1000"></canvas>
      </div>
    
    </div>

  );
}

export default Evan;
