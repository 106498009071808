import React from 'react';
import SplashScene from '../projects/SplashScene.jsx';
import Evan from '../projects/Evan.jsx';


function OutsideShop() {

	return (
		<Evan />
	)
}

export default OutsideShop;