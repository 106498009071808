import './App.css';
import OutsideShop from './components/OutsideShop.jsx';

function App() {

  return (
    <div className="App" id="app">
      <div>
        <OutsideShop />
      </div>
    </div>
  );
}

export default App;
